import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'

const CLASS_OPEN = 'm-flyout--open'
const CLASS_BTN = 'm-flyout__btn'
const CLASS_CONTAINER = 'm-flyout__container'
const CLASS_TRANSITION = 'm-flyout--transition'
const CLASS_BTN_MOUSEOVER = 'm-flyout__btn--mouseOver'

export default (() => {
  class Flyout {
    constructor(controller, elem) {
      this.initialized = false
      this.controller = controller
      this.elem = elem
      if (!this.elem) return
      if (this.elem.dataset.btnSelector) {
        this.btns = [...document.querySelectorAll(this.elem.dataset.btnSelector)]
      } else {
        this.btns = [...this.elem.querySelectorAll(`.${CLASS_BTN}`)]
      }
      this.container = this.elem.querySelector(`.${CLASS_CONTAINER}`)
      this.hasTransition = this.elem.classList.contains(CLASS_TRANSITION)
      this.rapidContainerId = this._getRapidContatinerId()
      this.ylk = ylkStringToObj(this.btns[0]?.dataset.ylk || this.elem?.dataset.ylk || '')
      this.focusableItems = [...this.container.querySelectorAll('a,button,input,[tabindex]')]
      this._init()
    }

    // Getters / Setters
    get isOpen() {
      return this.elem.classList.contains(CLASS_OPEN)
    }

    // Public methods
    toggle() {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    }

    open() {
      if (this.isOpen) return
      // Close all other flyouts so that only one is open at a time
      this.controller.closeAll()
      this.elem.classList.add(CLASS_OPEN)
      this.updatePosition()
      const transitionTime = this.hasTransition ? 200 : 0
      setTimeout(() => {
        this.refreshRapid()
        this._focusOnFirstElem()
        this._fireRapidBeacon({ elm: 'expand' })
      }, transitionTime)
    }

    close() {
      if (!this.isOpen) return
      this.elem.classList.remove(CLASS_OPEN)
      this._fireRapidBeacon({ elm: 'close' })
    }

    refreshRapid() {
      rapidReady(rapid => {
        if (!this.rapidContainerId) return
        if (rapid.isModuleTracked(this.rapidContainerId)) {
          rapid.refreshModule(this.rapidContainerId)
        } else {
          rapid.addModulesWithViewability([this.rapidContainerId])
        }
      })
    }

    updatePosition() {
      if (this.hasTransition || !this.isOpen) return
      const viewportWidth = window.innerWidth
      const rect = this.container.getBoundingClientRect()
      // Ensures the flyout doesn't go off the right side of the screen
      if (rect.right > viewportWidth) {
        this.container.style.transform = 'unset'
        this.container.style.left = 'unset'
        this.container.style.right = 0
      }
      // Ensures the flyout doesn't go off the left side of the screen
      else if (rect.left < 0) {
        this.container.style.transform = 'unset'
        this.container.style.left = 0
        this.container.style.right = 'unset'
      }
    }

    // Private methods
    _init() {
      this._addEventListeners()
      this.initialized = true
    }

    _addEventListeners() {
      this.btns?.forEach(btn => btn.addEventListener('click', this._handleClick.bind(this)))
      this.btns?.forEach(btn => {
        if (btn.classList.contains(CLASS_BTN_MOUSEOVER)) {
          btn.addEventListener('mouseenter', this._handleMouseEnter.bind(this))
        }
      })

      // This Closes the flyout when the user tabs out
      this.container.addEventListener('focusout', e => {
        const newFocusedElem = e.relatedTarget
        if (newFocusedElem && !this.container.contains(newFocusedElem)) {
          this.close()
        }
      })
    }

    _handleClick(e) {
      this.toggle()
    }

    _handleMouseEnter(e) {
      const btn = e.currentTarget
      this._fireRapidBeacon({ elm: 'expand', itc: 2 }, btn)
    }

    _getRapidContatinerId() {
      if (this.elem.dataset.rapid === 'true') {
        return this.elem.id
      }
      const rapidMod = this.elem.closest('div[data-rapid="true"]')
      return rapidMod?.id
    }

    _fireRapidBeacon(overrides = {}, elm) {
      if (!this.rapidContainerId) return
      const ylk = { ...this.ylk, ...overrides }
      rapidReady(rapid => {
        rapid.beaconClick(ylk.sec, ylk.slk, ylk.pos || elm?.dataset?.rapid_p, ylk)
      })
    }

    _focusOnFirstElem() {
      if (this.focusableItems.length)
        setTimeout(() => {
          this.focusableItems[0].focus()
        }, 1)
    }
  }
  return Flyout
})()
